@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  @font-face {
    font-family: Poppins;
    src: url("../src/fonts/Poppins/Poppins-ExtraLight.ttf");
    font-weight: 100;
  }

  @font-face {
    font-family: Poppins;
    src: url("../src/fonts/Poppins/Poppins-ExtraLightItalic.ttf");
    font-weight: 100;
    font-style: italic;
  }

  @font-face {
    font-family: Poppins;
    src: url("../src/fonts/Poppins/Poppins-Light.ttf");
    font-weight: 200;
  }

  @font-face {
    font-family: Poppins;
    src: url("../src/fonts/Poppins/Poppins-LightItalic.ttf");
    font-weight: 200;
    font-style: italic;
  }

  
  @font-face {
    font-family: Poppins;
    src: url("../src/fonts/Poppins/Poppins-MediumItalic.ttf");
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: Poppins;
    src: url("../src/fonts/Poppins/Poppins-Regular.ttf");
    font-weight: 400;
  }

  @font-face {
    font-family: Poppins;
    src: url("../src/fonts/Poppins/Poppins-Italic.ttf");
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: Poppins;
    src: url("../src/fonts/Poppins/Poppins-Medium.ttf");
    font-weight: 500;
  }

    @font-face {
      font-family: Poppins;
      src: url("../src/fonts/Poppins/Poppins-MediumItalic.ttf");
      font-weight: 500;
      font-style: italic;
    }

  @font-face {
    font-family: Poppins;
    src: url("../src/fonts/Poppins/Poppins-SemiBold.ttf");
    font-weight: 600;
  }

  @font-face {
    font-family: Poppins;
    src: url("../src/fonts/Poppins/Poppins-Bold.ttf");
    font-weight: 800;
  }

  @font-face {
    font-family: Rubik;
    src: url("../src/fonts/Rubik/Rubik-VariableFont_wght.ttf");
    font-weight: 300;
  }

  @font-face {
    font-family: Rubik;
    src: url("../src/fonts/Rubik/Rubik-VariableFont_wght.ttf");
    font-weight: 400;
  }

  @font-face {
    font-family: Rubik;
    src: url("../src/fonts/Rubik/Rubik-VariableFont_wght.ttf");
    font-weight: 500;
  }
  @font-face {
    font-family: Rubik;
    src: url("../src/fonts/Rubik/Rubik-VariableFont_wght.ttf");
    font-weight: 900;
  }
}

h1 {
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 32px;
}

h2 {
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 28px;
}

h3 {
  font-family: Rubik, sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.subheading {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 18px;
}


/** old css */

/* Author: Ciaran O'Keeffe (Ciaran OKeeffe)
Copyright © 2023 Ciaran OKeeffe All Rights Reserved */

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-new-background;
  font-size: 14px;
}
p {
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li:active {
  background-color: #FBFBFB !important;
}

.dropdown-toggle::after {
  display: none;
}

.navbar-toggler {
  border:0px;
}

input[type=file]::file-selector-button {
  background-color: #fff;
  color: #000;
  border: 0px;
  border-right: 1px solid #e5e5e5;
  padding: 10px 15px;
  margin-right: 20px;
  transition: .5s;
}

input[type=file]::file-selector-button:hover {
  background-color: #eee;
  border: 0px;
  border-right: 1px solid #e5e5e5;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
   border-color:#000000 !important;
   background-color: #FFFFFF !important;
    color: #555 !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: #000000 !important;}



input[type=checkbox]:checked{
  accent-color: #3c3c3c;
  background-color: #3c3c3c !important;
  border: 0;
}

::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.popover-arrow {
  display: none !important;
}

/** Input Overrides*/
.form-label {
  @apply mb-0 text-xs font-extralight
}

.form-control{
  @apply placeholder:text-aux-gray placeholder:text-sm rounded-none border-aux-gray
}
.was-validated .form-control:invalid:focus{
  @apply shadow-none
}
.was-validated .form-control:valid:focus {
  @apply border-aux-gray shadow-none
}
.was-validated .form-control:valid {
  @apply border-aux-gray bg-none
}
.was-validated .form-control:invalid{
  @apply border-red-accent bg-none
}
.invalid-feedback {
 @apply text-red-accent
}

/** Modal Overrides */

.modal {
  @apply flex justify-center items-center !important;
}

.modal-dialog {
  @apply w-full !important;
  @apply h-full md:h-auto p-0 m-0 rounded-none !important;
}

.modal-content {
  @apply h-full md:h-auto border-none rounded-none md:rounded-md p-5 md:p-9 !important;
}

.modal-footer {
  @apply border-t-0 !important;
  @apply pt-0 px-0 md:px-3 md:pt-3 !important;
}

.modal-header {
  @apply border-b-0 pb-0 px-0 md:px-4 !important;
}

.modal-body {
  @apply p-0 pb-0  !important;
  @apply  md:px-4 !important;
}


/** Search Box Overrides */

.ais-SearchBox-input {
  @apply rounded-none shadow-none border-aux-gray text-sm md:text-base !important;
}

.ais-SearchBox-input::placeholder {
  @apply text-main opacity-80 !important;
}

/** Replace default icon for a black one */
.ais-SearchBox-form::before {
  background: rgba(0, 0, 0, 0) url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" /></svg>') repeat scroll 0% 0% !important;
}

.ais-Hits-item {
  @apply p-0 md:py-4 shadow-none !important;
}

.ais-Snippet-highlighted, .ais-Highlight-highlighted {
  @apply bg-main text-white !important;
}

/** Bootstrap carousel overrides */

.carousel-item {
  @apply rounded-none !important;
}

.carousel .card {
  @apply border-none !important;
  @apply bg-transparent !important;
}


/** creatable overrides */

#creatable-select-share-user .css-13cymwt-control {
  @apply rounded-none;
  @apply border border-aux-gray;
}

#custom-select .css-1im77uy-control {
  @apply border border-transparent;
}

#creatable-select-share-user #react-select-3-placeholder {
  @apply text-sm;
  @apply font-light !important;
}

#creatable-select-share-user #react-select-3-listbox {
  @apply text-base;
  @apply rounded-none;
}

#creatable-select-share-user .css-1p3m7a8-multiValue {
  @apply bg-white;
  @apply border border-aux-gray;

}

#creatable-select-share-user .css-wsp0cs-MultiValueGeneric {
  @apply text-base !important;
}

#creatable-select-share-user .css-12a83d4-MultiValueRemove {
  @apply hover:bg-white ;
}

#users-select .select__multi-value {
  @apply bg-white;
  @apply border border-aux-gray;

}

#users-select .select__placeholder {
  @apply text-base;
  @apply font-light !important;
}

#users-select .select__control  {
  @apply rounded-none;
}


